import { css } from "@emotion/react"
import { useLocation } from "@reach/router"
import { graphql, Link, useStaticQuery } from "gatsby"
import camelCase from "lodash/camelCase"
import { FC, Fragment } from "react"

type Props = {
  internal?: boolean
  langKey: string
}

const Header: FC<Props> = ({ internal, langKey = "zh-hant" }) => {
  const location = useLocation()
  const { sanitySiteSettings, internalLinks, links } = useStaticQuery(query)

  const _langKey = camelCase(langKey)
  // const nav = { "zh-hant": zhHant, en }
  const prefix = langKey === "zh-hant" ? "" : "en/"

  return (
    <Fragment>
      <div
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          width: 100%;
          z-index: 31;
        `}
      >
        <div
          css={css`
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: flex-end;
          `}
        >
          <div
            css={css`
              width: 66.67px;
              background-color: red;
              margin-right: ${internal ? "2.35rem" : "2.8rem"};
              height: 26px;
              background-color: #f4f4f4;
              border: 1px solid #b2b2b2;
              border-bottom-right-radius: 4px;
              border-bottom-left-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              box-shadow: -5px 5px 10px -4px #000;
              -webkit-box-shadow: 0 5px 20px -9px #000;
              -moz-box-shadow: -5px 5px 10px -4px #000;

              a {
                color: #979797;
                text-decoration: none;
                cursor: pointer !important;
              }
            `}
          >
            <a
              href={
                langKey === "en"
                  ? `${location.pathname.replace("/en", "")}`
                  : `/en${location.pathname}`
              }
            >
              {langKey === "en" ? "中文" : "English"}
            </a>
          </div>
        </div>
      </div>
      <header
        css={css`
          position: ${internal ? "fixed" : "absolute"};
          top: 0;
          right: 0;
          left: 0;
          width: ${internal ? "100%" : "calc(100% - 0.7rem)"};
          z-index: 10;
          background-color: #fff;
          color: #161616;
          font-size: 1rem;
          height: 120px;
          padding: 2rem 0.5rem;
          margin-left: ${internal ? "0" : "-2px"};

          nav {
            height: 100%;
            max-width: 1200px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
              margin-left: 46px;
              width: 149px;
              height: 59px;
            }

            ul {
              list-style: none;
              margin: 0.5rem 0.5rem 0 0;
              padding-left: 0;
              text-transform: capitalize;

              li {
                margin-top: -0.5rem;
                display: block;
                float: left;
                padding: 1rem 1.55rem;
                position: relative;
                text-decoration: none;
                text-transform: capitalize;
                transition-duration: 0.5s;

                &:hover {
                  cursor: pointer;

                  ul {
                    visibility: visible;
                    opacity: 1;
                    display: block;

                    li:hover {
                      color: red;
                    }
                  }
                }

                ul {
                  visibility: hidden;
                  opacity: 0;
                  border-radius: 0.5rem;
                  width: 100%;
                  min-width: 5rem;
                  position: absolute;
                  transition: all 0.5s ease;
                  margin-top: 1.5rem;
                  left: 0;
                  display: none;
                  text-transform: capitalize;

                  li {
                    background: rgba(255, 255, 255, 1);
                    padding: 1rem 0.5rem;
                    clear: both;
                    width: 100%;
                    text-align: center;
                    font-size: 0.9rem;
                    text-transform: capitalize;

                    &:last-child {
                      border-bottom-left-radius: 0.5rem;
                      border-bottom-right-radius: 0.5rem;
                    }
                  }
                }
              }
            }

            a {
              text-decoration: none;
              color: inherit;
            }
          }

          // .link-active {
          //   color: #4ab344;
          // }
        `}
      >
        <nav role="navigation">
          <Link to="/" activeClassName={"link-active"}>
            <img src={sanitySiteSettings.logo.asset.url} />
          </Link>

          <ul>
            {links.edges.map(({ node }, i) => {
              return (
                <li key={`nav-li-${i}`}>
                  {node.url ? (
                    <Link
                      to={`/${prefix}${node.name.en
                        .toLowerCase()
                        .trim()
                        .split(" ")
                        .join("-")}`}
                    >
                      {node.name[_langKey]}
                    </Link>
                  ) : (
                    node.name[_langKey]
                  )}
                  {node.subPages && (
                    <ul>
                      {node.subPages.map((subPage, i) => (
                        <li key={`nav-li-ul-${i}`}>
                          <Link
                            to={`/${prefix}${subPage.name.en
                              .toLowerCase()
                              .trim()
                              .split(" ")
                              .join("-")}`}
                          >
                            {subPage.name[_langKey]}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
            })}

            {internalLinks.edges.map(({ node }, i) => {
              return (
                <li key={`nav-li-${i}`}>
                  {node.url ? (
                    <Link
                      to={`/${prefix}${node.name.en
                        .toLowerCase()
                        .trim()
                        .split(" ")
                        .join("-")}`}
                    >
                      {node.name[_langKey]}
                    </Link>
                  ) : (
                    node.name[_langKey]
                  )}
                  {node.subPages && (
                    <ul>
                      {node.subPages.map((subPage, i) => (
                        <li key={`nav-li-ul-${i}`}>
                          <Link
                            to={`/${prefix}${subPage.name.en
                              .toLowerCase()
                              .trim()
                              .split(" ")
                              .join("-")}`}
                          >
                            {subPage.name[_langKey]}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
            })}
            {/* {nav[langKey].edges.map(({ node }, i) => {
              return (
                <li key={`nav-li-${i}`}>
                  {node.href ? (
                    <Link to={node.href}>{node.title}</Link>
                  ) : (
                    node.title
                  )}
                  {node.subItems && (
                    <ul>
                      {node.subItems.map((subitem, i) => (
                        <li key={`nav-li-ul-${i}`}>
                          <Link to={subitem.href}>{subitem.title}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
            })} */}
          </ul>
        </nav>
      </header>
    </Fragment>
  )
}

export default Header

const query = graphql`
  {
    # zhHant: allPageInfoYaml(
    #   filter: { langKey: { eq: "zh-hant" }, href: { ne: "/" } }
    # ) {
    #   edges {
    #     node {
    #       title
    #       href
    #       subItems {
    #         title
    #         href
    #       }
    #     }
    #   }
    # }

    # en: allPageInfoYaml(
    #   filter: { langKey: { eq: "en" }, href: { ne: "/en/" } }
    # ) {
    #   edges {
    #     node {
    #       title
    #       href
    #       subItems {
    #         title
    #         href
    #       }
    #     }
    #   }
    # }

    links: allSanityFramedPages(sort: { fields: order, order: ASC }) {
      edges {
        node {
          name {
            en
            zhHant
          }
          subPages {
            name {
              en
              zhHant
            }
            url {
              en
              zhHant
            }
          }
        }
      }
    }

    internalLinks: allPageInfoYaml {
      edges {
        node {
          name {
            zhHant
            en
          }
          subPages {
            name {
              zhHant
              en
            }
            url {
              zhHant
              en
            }
          }
        }
      }
    }

    sanitySiteSettings {
      logo {
        asset {
          url
        }
      }
    }
  }
`
